import { CookiesProvider } from "react-cookie";
import '../styles/index.scss';
import 'swiper/css';  // eslint-disable-line
import 'swiper/scss/navigation';  // eslint-disable-line
import 'swiper/scss/pagination';  // eslint-disable-line

const App = ({ Component, pageProps }) => {
  return (
    <CookiesProvider>
      <Component {...pageProps} />
    </CookiesProvider>
  )
};

export default App;
