var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"sTZmN15X3lBlPuuqNAT9V"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Performance monitoring
  tracesSampleRate: 1.0,
  // Debug mode in development
  debug: process.env.NODE_ENV === 'development',
  environment: process.env.APP_ENV ? process.env.APP_ENV : process.env.NODE_ENV,

  beforeSend(event, hint) { // Filter-out 'Request aborted' errors (e.g. user navigates away before request completes)
    if (hint.originalException?.message?.includes('Request aborted')) {
      return null;
    }
    return event;
  },
});